import React from 'react';
import isAbsoluteUrl from 'is-absolute-url';
import Image from '../Image/Image';
import { ExternalEventLink, ExternalLink, InternalLink } from '../form-elements/Link';
import { fetchCouponsIfNeeded } from '../../../modules/actions/standort/coupons';
import { couponsResponseSelector } from '../../../modules/selectors/standort/coupons';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import CouponModal from '../CouponModal/CouponModal';

function VBoxLink({ item: { target: openInNewTab, url, title }, children, type, coupon }) {
    if (!url) {
        return children;
    }

    // let linkedCoupon;
    // coupons.forEach(coupon => {
    //     if (coupon.code === url) {
    //         linkedCoupon = coupon;
    //     }
    // });

    if (coupon) {
        return (
            <CouponModal coupon={coupon}>
                {({ openCouponModal }) => (
                    <span style={{cursor: 'pointer'}} onClick={openCouponModal}>{children}</span>
                )}
            </CouponModal>
        );
    } else if (isAbsoluteUrl(url)) {
        let eventLabel;
        if (type === 'teaser') {
            eventLabel = 'Klick-Kachel';
        } else if (type === 'slider') {
            eventLabel = 'Klick-Slider';
        }

        if (eventLabel) {
            return (
                <ExternalEventLink to={url} newTab={openInNewTab} action={eventLabel} label={title}>
                    {children}
                </ExternalEventLink>
            );
        } else {
            return (
                <ExternalLink to={url} newTab={openInNewTab}>
                    {children}
                </ExternalLink>
            );
        }
    } else {
        return <InternalLink to={`/${url}`}>{children}</InternalLink>;
    }
}

class Vbox extends React.Component {
    render() {
        const { item, type } = this.props;

        const { couponResponse } = this.props;
        let linkedCoupon;
        couponResponse.data?.forEach(coupon => {
            // console.log("code", coupon.code, "url", item.url);
            if (coupon.code === item.url) {
                // console.log("linked code", coupon.code)
                linkedCoupon = coupon;
            }
        });

        // console.log(couponResponse)
        const { x: width, y: height } = item;
        const viewBox = `0 0 ${width} ${height}`;
        return (
            <VBoxLink item={item} type={type} coupon={linkedCoupon}>
                <div className="uk-cover-container uk-height-1-1">
                    <Image src={item.dataUrl} alt={item.title} className="lazy-image uk-width-1-1 uk-cover" />
                    <canvas width={width} height={height} />
                    <div className="uk-position-cover">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                            {item?.price?.euro && (
                                <text
                                    fill={item.price.color}
                                    style={{ fontSize: '64px', fontWeight: 900 }}
                                    x={item.price.x}
                                    y={item.price.y}
                                >
                                    <tspan style={{ fontSize: '28px' }} fill={item.price.prefixColor}>
                                        {item.price.prefix}{' '}
                                    </tspan>
                                    <tspan dy={item.price.prefixPos || 0}>€ {item.price.euro},</tspan>
                                    {/*<tspan dy={28 / 64 + "em"}>€ {item.price.euro},</tspan>*/}
                                    <tspan dy="-24px" style={{ fontSize: '28px' }}>
                                        {item.price.cent}
                                    </tspan>
                                </text>
                            )}
                            {item?.button?.text && (
                                <g transform={`matrix(1 ,0 ,0 ,1 ,${item.button.x}, ${item.button.y})`}>
                                    <rect fill="#132623" width={item.button.width} height="50" />
                                    <path
                                        fill="var(--shop-main)"
                                        d="M 21.663 25 C 21.663 24.826 21.597 24.673 21.463 24.539 L 12.124 15.2 C 11.991 15.067 11.837 15 11.663 15 C 11.49 15 11.336 15.067 11.202 15.2 L 10.2 16.202 C 10.067 16.336 10 16.49 10 16.663 C 10 16.837 10.067 16.991 10.2 17.124 L 18.076 25 L 10.2 32.876 C 10.067 33.009 10 33.163 10 33.337 C 10 33.51 10.067 33.664 10.2 33.798 L 11.202 34.8 C 11.336 34.933 11.49 35 11.663 35 C 11.837 35 11.991 34.933 12.124 34.8 L 21.463 25.461 C 21.597 25.327 21.663 25.174 21.663 25 Z M 29.359 25 C 29.359 24.826 29.292 24.673 29.158 24.539 L 19.82 15.2 C 19.686 15.067 19.532 15 19.359 15 C 19.185 15 19.031 15.067 18.898 15.2 L 17.896 16.202 C 17.762 16.336 17.695 16.49 17.695 16.663 C 17.695 16.837 17.762 16.991 17.896 17.124 L 25.772 25 L 17.896 32.876 C 17.762 33.009 17.695 33.163 17.695 33.337 C 17.695 33.51 17.762 33.664 17.896 33.798 L 18.898 34.8 C 19.031 34.933 19.185 35 19.359 35 C 19.532 35 19.686 34.933 19.82 34.8 L 29.158 25.461 C 29.292 25.327 29.359 25.174 29.359 25 Z"
                                    />
                                    <text
                                        fill="#fff"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: 'normal',
                                            textTransform: 'uppercase',
                                        }}
                                        x="35"
                                        y="32"
                                    >
                                        {item.button.text}
                                    </text>
                                </g>
                            )}
                            {item.rows.map((row, index) => {
                                const sizeString = row.size.toString();
                                const fontSize = sizeString.includes('px') ? sizeString : `${row.size}px`;
                                return (
                                    <text
                                        key={index}
                                        textAnchor={row.anchor}
                                        x={row.x}
                                        y={row.y}
                                        style={{
                                            fill: row.color,
                                            fontSize, // 22px o. 36px
                                            fontWeight: row.weight,
                                        }}
                                    >
                                        {row.title}
                                    </text>
                                );
                            })}
                        </svg>
                    </div>
                </div>
            </VBoxLink>
        );
    }
}

const frontload = async ({ fetchCouponsIfNeeded }) => {
    await fetchCouponsIfNeeded();
};

const mapStateToProps = (state) => {
    return {
        couponResponse: couponsResponseSelector(state),
    };
};

const mapDispatchToProps = { fetchCouponsIfNeeded };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(frontloadConnect(frontload)(Vbox));
