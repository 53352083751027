import { useSelector } from 'react-redux';
import { hideHomeHeroBannerSelector, hideServiceFormSelector, mobileVehicleSelectionSelector } from '../../../modules/selectors/standort/theme';
import React, { useRef, useState, useEffect } from 'react';
import { markenStandortSelector, markenstandortHasFahrzeugSpezifischeSucheSelector } from '../../../modules/selectors/standort/markenStandort';
import HomeHeroBanner from '../../components/Home/HomeHeroBanner';
import BreadCrumbSelector from '../../components/BreadCrumb/BreadCrumbSelector';
import TopSeller from '../../components/TopSeller/TopSeller';
import VehicleSelectionProducts from '../../components/vehicleSelectionProducts/VehicleSelectionProducts'
import OfferCategories from '../../components/Categories/OfferCategoryCards';
import ServiceCategories from '../../components/ServiceCategories/ServiceCategories';
import Meisterkachel from '../../components/Meisterkachel/Meisterkachel';
import SubCategoryLinks from '../../components/Categories/SubCategoryLinks';
import VehicleSelectionMobileToggle from '../../components/VehicleSelection/VehicleSelectionMobileToggle';
import loadable from '@loadable/component';
import { markenStandortHasDatSelector } from '../../../modules/selectors/standort/markenStandort';

const CMSTeaser = loadable(() => import('../../components/CMSTeaser/CMSTeaser'));
const SubLocationFinder = loadable(() => import('../../components/SubLocationFinder/SubLocationFinder'));
const ServiceStationFinder = loadable(() => import('../../components/ServiceStationFinder/ServiceStationFinder'));

export default function DefaultHome() {
    const markenStandort = useSelector(markenStandortSelector);
    const hideHomeHeroBanner = useSelector(hideHomeHeroBannerSelector);
    const mobileVehicleSelection = useSelector(mobileVehicleSelectionSelector);
    const ServiceCategoriesRef = useRef(null);
    const hideServiceForm = useSelector(hideServiceFormSelector);
    const hasDat = useSelector(markenStandortHasDatSelector);
    const hasFSS = useSelector(markenstandortHasFahrzeugSpezifischeSucheSelector)

    const [isMobile, setIsMobile] = useState(null);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 960);
        }

        if (typeof window !== 'undefined') {
            handleResize();
        } 

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile]);

    return (
        <>
            {!hideHomeHeroBanner && !isMobile && isMobile !== null && <HomeHeroBanner />}
            {hasDat && isMobile && isMobile !== null && !mobileVehicleSelection && hasFSS && (
                <VehicleSelectionMobileToggle />
            )}
            <BreadCrumbSelector />
            {hasDat && isMobile && isMobile !== null && mobileVehicleSelection && hasFSS &&(
                <VehicleSelectionMobileToggle />
            )}
            {hasDat && !isMobile && isMobile !== null && hasFSS && (
                <VehicleSelectionMobileToggle />
            )}
            {hasFSS && <VehicleSelectionProducts />}
            <TopSeller />
            <CMSTeaser h3="Unsere Highlights" previewCategory="main" />
            {/* <OfferCategories /> */}
            {markenStandort.isTechno ? (
                <div className="uk-section uk-section-default uk-section-small">
                    <div className="uk-container">
                        <h2 className="uk-text-normal">
                            Geben Sie Ihre <b>Postleitzahl</b> ein und Sie erhalten eine Auswahl an Markenwerkstätten in <b>Ihrer Nähe</b>
                        </h2>
                        <ServiceStationFinder />
                    </div>
                </div>
            ) : (
                <SubLocationFinder />
            )}
            {!hideServiceForm && <ServiceCategories ref={ServiceCategoriesRef} />}
            <section className="uk-section uk-section-muted uk-section-small uk-padding">
                <div className="uk-container uk-container-xsmall">
                    <Meisterkachel />
                </div>
            </section>
            <SubCategoryLinks />
        </>
    );
}
