import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReduxSuspense from '../ReduxSuspense/ReduxSuspense';
import ServiceCategoryCards from './ServiceCategoryCards';
import { InternalEventLink } from '../form-elements/Link';
import { dienstleistungenResponseSelector } from '../../../modules/selectors/standort/dienstleistungen';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import _ from 'lodash';
import { apiClient } from '../../../helpers/apiHelperV2';

import useSWR from 'swr';

const FeaturedCategoryCards = ({ dienstleistungen }) => {
    const markenStandort = useSelector(markenStandortSelector);

    const limitedOrderedServices = useMemo(() => {
        const orderedServiceUrls = markenStandort.homeCategories?.ServiceArea ?? [];

        const orderedServices = _.isEmpty(orderedServiceUrls)
            ? dienstleistungen
            : _.compact(orderedServiceUrls.map(url => _.find(dienstleistungen, { url })));

        return orderedServices.slice(0, 8);
    }, [dienstleistungen, markenStandort]);

    return (
        <ServiceCategoryCards
            dienstleistungen={limitedOrderedServices}
            trackingAction="Klick-Servicekachel-Home"
            limitMobileCardCount={6}
        />
    );
};

const ServiceCategories = React.forwardRef((props, ref) => {
    const dienstleistungenResponse = useSelector(dienstleistungenResponseSelector);
    const markenStandort = useSelector(markenStandortSelector);

    const { data: serviceNavigatorstatus } = useSWR(`/api/v1/getServicenavigatorStatus?standortId=${markenStandort._id}`, async url => {
        const { data } = await apiClient.get(url);
        return data;
    });

    const serviceNavigatorStatus = useMemo(() => serviceNavigatorstatus?.status ?? [], [serviceNavigatorstatus]);

    return (
        <>

            {!serviceNavigatorStatus && (
                <section ref={ref} className="uk-section uk-section-muted">
                    <div className="uk-container">
                        <h2 className="uk-text-light">Reparatur & Wartung</h2>
                        <h3>Das sind unsere Service-Leistungen für Sie</h3>
                        <ReduxSuspense response={dienstleistungenResponse}>
                            {dienstleistungen => <FeaturedCategoryCards dienstleistungen={dienstleistungen} />}
                        </ReduxSuspense>
                        <div className="uk-grid uk-child-width-1-1" data-uk-grid="">
                            <div className="uk-flex uk-flex-center">
                                <InternalEventLink
                                    action="Klick-Servicearea-Home"
                                    label="weitereLeistungen"
                                    category="Home"
                                    to="/reparatur-wartung"
                                >
                                    Weitere Leistungen
                                </InternalEventLink>
                            </div>
                            <div className="uk-flex uk-flex-center">
                                <InternalEventLink
                                    action="Klick-Servicearea-Home"
                                    label="serviceAnfrage"
                                    category="Home"
                                    to="/serviceAnfrage"
                                    modifier="secondary"
                                >
                                    SERVICE ANFRAGEN
                                </InternalEventLink>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );

});

export default ServiceCategories;
